// config.js


// PRODUCTION

const API_URL = 'https://panelnoticias.certificadounicovehicular.com';

// DEVELOPER

// const API_URL = 'http://192.168.1.104:8000';


export { API_URL };
