import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_URL } from '../../config';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, Button, CardActions, IconButton, TextField, Avatar, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShareIcon from '@mui/icons-material/Share';
import ModeCommentIcon from '@mui/icons-material/ModeComment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export default function DetailPosts() {
    const { id } = useParams();
    const [post, setPost] = useState(null);
    const [isLiked, setIsLiked] = useState(false);
    const [likesCount, setLikes] = useState(0);
    const [comments, setComments] = useState([]);
    const [commentText, setCommentText] = useState('');
    const [replyText, setReplyText] = useState({});
    const [showReplyField, setShowReplyField] = useState({});

    useEffect(() => {
        const isLikedLocally = localStorage.getItem(`liked_${id}`) === 'true';
        setIsLiked(isLikedLocally);

        fetch(`${API_URL}/api/v1/posts/${id}`)
            .then(response => response.json())
            .then(data => {
                setPost(data);
                const loadedComments = data.comments.map(comment => ({
                    ...comment,
                    isLiked: localStorage.getItem(`liked_comment_${comment.id}`) === 'true',
                    child_comments: comment.child_comments.map(child => ({
                        ...child,
                        comment: {
                            ...child.comment,
                            isLiked: localStorage.getItem(`liked_child_comment_${child.comment.id}`) === 'true',
                        }
                    }))
                }));

                if (data.image.startsWith('/storage')) {
                    data.image = `${API_URL}${data.image}`;
                }
                setComments(loadedComments);
                setLikes(data.likes || 0);
            })
            .catch(error => console.error('Error fetching post:', error));
    }, [id]);




    const handleLike = async () => {
        try {
            const response = await fetch(`${API_URL}/api/v1/posts/${id}/like`, {
                method: isLiked ? 'DELETE' : 'POST',
            });

            if (response.ok) {
                const updatedLikes = isLiked ? likesCount - 1 : likesCount + 1;
                setIsLiked(!isLiked);
                setLikes(updatedLikes);
                localStorage.setItem(`liked_${id}`, !isLiked);
            } else {
                console.error('Failed to toggle like');
            }
        } catch (error) {
            console.error('Error while toggling like:', error);
        }
    };

    const handleLikeComment = async (commentId) => {
        try {
            const comment = comments.find(c => c.id === commentId);
            const localStorageKey = `liked_comment_${commentId}`;
            const isLikedLocally = localStorage.getItem(localStorageKey) === 'true';

            const response = await fetch(`${API_URL}/api/v1/comments/${commentId}/like`, {
                method: isLikedLocally ? 'DELETE' : 'POST',
            });

            if (response.ok) {
                const updatedComment = {
                    ...comment,
                    likes: isLikedLocally ? comment.likes - 1 : comment.likes + 1,
                    isLiked: !isLikedLocally
                };
                setComments(comments.map(c => (c.id === commentId ? updatedComment : c)));
                localStorage.setItem(localStorageKey, !isLikedLocally);
            }
        } catch (error) {
            console.error('Error liking comment:', error);
        }
    };

    const handleLikeChildComment = async (childCommentId) => {
        try {
            const comment = comments.find(c => c.child_comments.some(child => child.comment.id === childCommentId));
            const childComment = comment.child_comments.find(child => child.comment.id === childCommentId).comment;
            const localStorageKey = `liked_child_comment_${childCommentId}`;
            const isLikedLocally = localStorage.getItem(localStorageKey) === 'true';

            const response = await fetch(`${API_URL}/api/v1/comments/${childCommentId}/like`, {
                method: isLikedLocally ? 'DELETE' : 'POST',
            });

            if (response.ok) {
                const updatedChildComment = {
                    ...childComment,
                    likes: isLikedLocally ? childComment.likes - 1 : childComment.likes + 1,
                    isLiked: !isLikedLocally
                };

                const updatedComments = comments.map(c =>
                    c.id === comment.id
                        ? {
                            ...c,
                            child_comments: c.child_comments.map(child =>
                                child.comment.id === childCommentId ? { ...child, comment: updatedChildComment } : child
                            )
                        }
                        : c
                );

                setComments(updatedComments);
                localStorage.setItem(localStorageKey, !isLikedLocally);
            }
        } catch (error) {
            console.error('Error liking child comment:', error);
        }
    };


    const handleShare = () => {
        const postUrl = `${window.location.origin}/post/${id}`;
        shareContent(post.title, postUrl);
    };

    const shareContent = async (title, url) => {
        try {
            if (navigator.share) {
                await navigator.share({
                    title: title,
                    url: url,
                });
            } else {
                console.log('Web Share API not supported');
            }
        } catch (error) {
            console.error('Error sharing content:', error);
        }
    };



    const handleSubmitComment = async () => {
        try {
            const newComment = { post_id: id, message: commentText };
            const response = await fetch(`${API_URL}/api/v1/posts/${id}/comments`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newComment),
            });
            if (!response.ok) throw new Error('Failed to submit comment');
            const savedComment = await response.json();
            setComments([savedComment, ...comments]);
            setCommentText('');
        } catch (error) {
            console.error('Error submitting comment:', error);
        }
    };

    const handleReplyChange = (e, commentId) => {
        setReplyText({ ...replyText, [commentId]: e.target.value });
    };

    const handleReplySubmit = async (commentId) => {
        try {
            const newReply = { post_id: id, message: replyText[commentId], parent_id: commentId };
            const response = await fetch(`${API_URL}/api/v1/posts/${id}/comments/${commentId}/reply`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newReply),
            });
            if (!response.ok) throw new Error('Failed to submit reply');
            const savedReply = await response.json();

            const updatedComments = comments.map(comment =>
                comment.id === commentId
                    ? { ...comment, child_comments: [...(comment.child_comments || []), { comment: savedReply }] }
                    : comment
            );
            setComments(updatedComments);
            setReplyText({ ...replyText, [commentId]: '' });
            setShowReplyField({ ...showReplyField, [commentId]: false });
        } catch (error) {
            console.error('Error submitting reply:', error);
        }
    };

    if (!post) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', color: "#fff", fontWeight: 800, fontSize: "30px" }}>
                <DirectionsCarIcon style={{ fontSize: "32px", marginRight: "12px" }} />
                Cargando...
            </div>
        );
    }

    const { title, image, description, fountain } = post;

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", padding: "10px" }}>
            <Button
                variant="outlined"
                startIcon={<ArrowBackIcon />}
                style={{ alignSelf: 'flex-start', marginTop: "20px", margin: "5px", marginBottom: '20px', border: "0px", color: "#fff", background: "rgba(41,41,41, 0.7)", borderRadius: "20px" }}
                onClick={() => {
                    const currentPosition = window.pageYOffset;
                    window.history.back();
                    setTimeout(() => {
                        window.scrollTo(0, currentPosition);
                    }, 0);
                }}
            >
                Regresar
            </Button>

            <div style={{ marginTop: "auto" }}>
                <div style={{ padding: "2px", background: "rgba(41,41,41, 0.7)", borderRadius: "20px" }}>
                    <div style={{ flex: 1, margin: "20px" }}>
                        <Typography variant="h5" component="div" style={{ fontWeight: 900, color: "#fff" }}>{title}</Typography>
                    </div>
                    <div style={{ textAlign: "center", display: "flex", padding: "10px" }}>
                        <CardMedia component="img" src={image} alt={title} style={{ borderRadius: "20px" }} />
                    </div>

                    <CardActions disableSpacing style={{ paddingTop: '0', marginBottom: "-30px", marginTop: "20px", justifyContent: 'space-between' }}>
                        <IconButton aria-label="add to favorites" onClick={handleLike}>
                            {isLiked ? <FavoriteIcon style={{ color: "#ff0000" }} /> : <FavoriteBorderIcon style={{ color: "#fff" }} />}
                            <Typography variant="body2" color="#fff" style={{ marginLeft: "10px" }}>{likesCount} Me gusta</Typography>
                        </IconButton>
                        <IconButton aria-label="share" onClick={() => handleShare()} style={{ color: "#fff" }}>
                            <ShareIcon />
                        </IconButton>
                    </CardActions>

                    <Typography variant="body1" component="div" style={{ color: "#fff", marginTop: "20px", padding: "10px", textAlign: 'justify' }}>
                        <div dangerouslySetInnerHTML={{ __html: description }} />

                        {fountain && (
    <div style={{ fontWeight: 800, fontSize: "14px", color: "#fff", marginTop: "12px" }}>
        Fuente: <a href={fountain} target="_blank" rel="noopener noreferrer" style={{ color: "#fff" }}>Visitar enlace</a>
    </div>
)}

           
                    </Typography>



                </div>
                <div style={{ padding: "15px", marginTop: "20px", background: "#fff", borderRadius: "20px" }}>
                    <Typography variant="h6" style={{ color: "#000", marginTop: "12px", marginBottom: "24px" }}>Comentarios</Typography>
                    <Box sx={{ padding: 1 }}>
                        <Box component="form" sx={{ display: 'flex', alignItems: 'center', mb: 1 }} onSubmit={(e) => { e.preventDefault(); handleSubmitComment(); }}>
                            <TextField label="Añadir un comentario..." variant="outlined" fullWidth value={commentText} onChange={(e) => setCommentText(e.target.value)} sx={{ mr: 1 }} />
                            <Button type="submit" variant="contained">Enviar</Button>
                        </Box>
                        {comments.sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map(comment => (
                            <Paper key={comment.id} sx={{ mb: 2, padding: 2 }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar>{comment.user_name ? comment.user_name.charAt(0) : '?'}</Avatar>
                                    <Box sx={{ ml: 2 }}>
                                        <Typography variant="subtitle2">{comment.user_name || 'Anónimo'}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {comment.created_at ? format(new Date(comment.created_at), "MMMM dd, yyyy 'a las' hh:mm a", { locale: es }) : ''}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography variant="body1" sx={{ mt: 1 }}>{comment.message}</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <IconButton onClick={() => handleLikeComment(comment.id)}>
                                        {comment.isLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
                                        <Typography variant="body2" style={{ marginLeft: "3px" }}>{comment.likes}</Typography>
                                    </IconButton>
                                    <IconButton onClick={() => setShowReplyField({ ...showReplyField, [comment.id]: !showReplyField[comment.id] })}>
                                        <ModeCommentIcon style={{ width: "20px" }} />
                                        <Typography variant="body2" style={{ marginLeft: "5px" }}>Responder</Typography>
                                    </IconButton>
                                </Box>
                                {showReplyField[comment.id] && (
                                    <Box component="form" sx={{ display: 'flex', alignItems: 'center', mt: 1 }} onSubmit={(e) => { e.preventDefault(); handleReplySubmit(comment.id); }}>
                                        <TextField label="Escribe una respuesta..." variant="outlined" fullWidth value={replyText[comment.id] || ''} onChange={(e) => handleReplyChange(e, comment.id)} sx={{ mr: 1 }} />
                                        <Button type="submit" variant="contained">Enviar</Button>
                                    </Box>
                                )}
                                {comment.child_comments && comment.child_comments.map(child => (
                                    <Box key={child.id} sx={{ mt: 2, ml: 4 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar>{child.comment?.user_name ? child.comment.user_name.charAt(0) : '?'}</Avatar>
                                            <Box sx={{ ml: 2 }}>
                                                <Typography variant="subtitle2">{child.comment?.user_name || 'Anónimo'}</Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {child.comment?.created_at ? format(new Date(child.comment.created_at), "MMMM dd, yyyy 'a las' hh:mm a", { locale: es }) : ''}
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Typography variant="body1" sx={{ mt: 1 }}>{child.comment?.message || ''}</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                            <IconButton onClick={() => handleLikeChildComment(child.comment.id)}>
                                                {child.comment.isLiked ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
                                                <Typography variant="body2" style={{ marginLeft: "3px" }}>{child.comment.likes}</Typography>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                ))}

                            </Paper>
                        ))}
                        {comments.length === 0 && <div style={{ padding: "10px", backgroundColor: "#fff", borderRadius: "5px" }}>No hay comentarios</div>}
                    </Box>
                </div>
            </div>
        </div>
    );
}
